import React from "react"

export default function AccountDetail({ customer }) {
    const displayName = customer?.firstName ? `${customer.firstName} ${customer.lastName}` : customer?.displayName
    
    return (
        <div className="column is-half">
            <h4 className="subtitle is-family-secondary text-2xl pt-4">{displayName}</h4>
            <p className="py-4">{customer?.email}</p>
            <br />
            {
                customer?.defaultAddress && (
                    <div className="mb-4">
                        {customer.defaultAddress.formatted.map((line, index) => (
                            <p key={index}>{line}</p>
                        ))}
                    </div>
                )
            }
        </div>
    )
}
