import React from 'react'

export default function AccountOrders({ orders }) {

    const formatCurrency = ({ currencyCode, amount }) => {
        return new Intl.NumberFormat('en-GB', { style: 'currency', currency: currencyCode }).format(amount)
    }

    return (
        <div className="column is-half py-4">
            <h4 className="subtitle is-family-secondary text-dark font-bold text-2xl">
                Order history
            </h4>
            <div>
                {
                    orders.length ? (
                        <ul className="cart-list text-sm">
                            <li className="head flex pt-7 pb-5 border-b font-semibold border-gray-400">
                                <div className="flex-auto text-left mr-2">Order #</div>
                                <div className="col-price flex-auto text-center">Total Items</div>
                                <div className="col-quantity flex-auto text-center">Total Price</div>
                                <div className="col-quantity flex-fixed w-32 text-center"></div>
                            </li>
                    
                            {
                                orders.map((order, index) => (
                                    <li className="item flex pt-7 pb-5 border-b border-gray-400" key={index}>
                                        <div className="flex-auto text-left mr-2">{ order.name }</div>
                                        <div className="col-price flex-auto text-center">{ order.lineItems.edges.length }</div>
                                        <div className="col-quantity flex-auto text-center">{ formatCurrency(order.totalPrice) }</div>
                                        <div className="col-quantity flex-fixed w-32 text-center">
                                            <a href={order.statusUrl} target="_blank" rel="noreferrer" className="text-dark font-normal underline">
                                                View Order
                                            </a>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    ) : (
                        <p v-if="!orders.length" className="text-2xl text-dark py-4">
                            You haven't placed any orders yet.
                        </p>
                    )
                }
            </div>
        </div>
    )
}