import React from 'react'
import { Link, navigate } from 'gatsby'
import { SearchProvider } from '../../context/search-provider'
import { StoreContext } from "../../context/store-context"
import { useQuery } from 'urql'
import AccountDetail from '../../components/account/detail'
import AccountOrders from '../../components/account/orders'
import { Layout } from "../../components/layout/layout"

const CustomerOrdersQuery = `
query customerOrders ($accessToken: String!) {
    customer (customerAccessToken: $accessToken) {
        displayName
        firstName
        lastName
        email
        tags
        defaultAddress {
            address1
            address2
            city
            country
            zip
            formatted(withName: true)
        }
        orders (first: 10) {
            edges {
            node {
                id
                name
                statusUrl
                lineItems (first: 100) {
                edges {
                    node {
                    title
                    quantity
                    }
                }
                }
                totalPrice: totalPriceV2 {
                amount
                currencyCode
                }
            }
            }
        }
    }
}
`

function AccountPage() {
    const { token } = React.useContext(StoreContext)
    const [orders, setOrders] = React.useState([])

    const [result, reexecuteQuery] = useQuery({
        query: CustomerOrdersQuery,
        variables: {
            accessToken: token?.accessToken
        }
    })
    
    const { data, fetching, error } = result

    React.useEffect(() => {
        if (data?.customer) {
            setOrders(data?.customer?.orders.edges.map(({ node }) => node))
        }
    }, [data])

    React.useEffect(() => {
        if (!token?.accessToken) navigate('/account/login')
    }, [token])
    
    return (
        <Layout>
            <div className="hero w-full bg-primary text-white mx-auto flex flex-col sm:flex-col items-center justify-center py-20 px-5 relative">
                <div className="text-4xl font-bold w-full text-center">
                    <Link to="/account/login/" title="Login">
                        <h1 className="leading-tight font-normal text-45xl">Your Account</h1>
                    </Link>
                </div>
            </div>

            <div className="contact-body flex flex-col mb-4 w-full relative">
                {
                    fetching ? (
                        <div className="bg-background-overlay w-full h-64 flex items-center justify-center z-50">
                            <svg className="animate-spin h-6 w-6 bg-transparent border-2 border-gray-sm border-t-transparent rounded-full" viewBox="0 0 24 24"></svg>
                        </div>
                    ) : (
                        <div className='mx-auto py-4 px-4 max-w-2xl'>
                            <AccountDetail customer={data?.customer} />
                            <AccountOrders orders={orders} />
                        </div>
                    )
                }
            </div>
        </Layout>
    )
}

export default function AccountPageTemplate(props) {
    return (
        <SearchProvider>
            <AccountPage {...props} />
        </SearchProvider>
    )
}